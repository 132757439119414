

import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom'
import Projects from '../../api/project'
import RelatedProject from './related';
import Discuss from './discuss';
import Logo from '../../images/decoline.logo.png'
import Footer from '../../components/footer';

import { ProjectsI18n } from '../../i18n/projects';



const ProjectSinglePage = (props) => {
    const { id } = useParams()

    const { lang = "ka" } = useParams();  


    const projectDetails = ProjectsI18n.find((item) => item.id === id);

    console.log(projectDetails?.data["en"] ,  ProjectsI18n, id);

    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            {/* <PageTitle pageTitle={projectDetails.title} pagesub={'Project'} /> */}
            <div className="wpo-project-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="wpo-project-single-wrap">
                                <div className="wpo-project-single-item">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="wpo-project-single-title">
                                                {/* <h2>{projectDetails.title} </h2> */}
                                                <h3>{projectDetails?.data[lang].description}</h3>
                                            </div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="wpo-project-single-content-des-right">
                                                <ul>
                                                    <li>Location :<span>{projectDetails?.data[lang].location}</span></li>
                                                    <li>Client :<span>{projectDetails?.data[lang].client}</span></li>
                                                    <li>Architect :<span>{projectDetails?.data[lang].architect}</span></li>
                                                    <li>Project Type :<span>{projectDetails?.data[lang].projectType}</span></li>
                                                    <li>Duration :<span>{projectDetails?.data[lang].duration}</span></li>
                                                    <li>Completion :<span>{projectDetails?.data[lang].completion}</span></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpo-project-single-main-img">
                                        <img src={projectDetails?.data[lang].ps1img} alt="er"/>
                                    </div>
                                    <div className="wpo-project-single-item list-widget">
                                        <div className="row">
                                            {/* <div className="col-lg-6">
                                                <div className="wpo-project-single-title">
                                                    <h3>Our Strategies</h3>
                                                </div>
                                                <p>Massa volutpat odio facilisis purus sit elementum. Non, sed velit dictum
                                                    quam. Id
                                                    risus pharetra est, at rhoncus, nec ullamcorper tincidunt. Id aliquet duis
                                                    sollicitudin diam.</p>
                                                <ul>
                                                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                                                    <li>Id risus pharetra est, at rhoncus, nec ullamcorper tincidunt.</li>
                                                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                                                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                                                    <li>Elit curabitur amet risus bibendum.</li>
                                                </ul>
                                            </div> */}
                                            {/* <div className="col-lg-6">
                                                <div className="wpo-project-single-item-quote">
                                                    <p>"Amazing looking theme and instantly turns your application into a great
                                                        looking one. Really shows that pro_ fessionals built this theme up. Very
                                                        happy with the way the theme looks ."</p>
                                                    <span>Robert - <span>Yellow Theme</span></span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="wpo-project-single-item">
                                        {/* <div className="wpo-project-single-title">
                                            <h3>ფოტოები</h3>
                                        </div> */}
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat suspendisse aenean
                                            tellus augue morbi risus. Sit morbi vitae morbi sed urna sed purus. Orci facilisi
                                            eros sed pellentesque. Risus id sed tortor sed scelerisque. Vestibulum elit
                                            elementum, magna id viverra non, velit. Pretium, eros, porttitor fusce auctor vitae
                                            id. Phasellus scelerisque nibh eleifend vel enim mauris purus. Rutrum vel sem
                                            adipiscing nisi vulputate molestie scelerisque molestie ultrices. Eu, fusce
                                            vulputate diam interdum morbi ac a.</p> */}
                                    </div>





                                        {/* ---------------------------- ფოტოებისთის განკუთვნილი ადგილი --------------------- */}


                                    <div className="wpo-project-single-gallery">
                                        <div className="row mt-4"  >
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img1} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img2} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img3} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img4} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img5} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img6} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img7} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img8} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img9} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img10} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img11} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img12} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img13} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="wpo-p-details-img">
                                                    <img src={projectDetails?.data[lang].psub1img14} alt="" style={{aspectRatio: 337.5 / 190.5}}/>
                                                </div>
                                            </div>



                                        


                                        </div>
                                    </div>
                                    {/* <div className="wpo-project-single-item list-widget">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="wpo-project-single-title">
                                                    <h3>Resieved goals</h3>
                                                </div>
                                                <ul>
                                                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                                                    <li>Id risus pharetra est, at rhoncus, nec ullamcorper tincidunt.</li>
                                                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                                                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6 list-widget-s">
                                                <div className="wpo-project-single-title">
                                                    <h3>Results</h3>
                                                </div>
                                                <ul>
                                                    <li>Mauris dignissim blandit cursus imperdiet accumsan lorem.</li>
                                                    <li>Nam id in non sed cras purus nunc et.</li>
                                                    <li>Mauris orci, cursus nisl odio est adipiscing gravida magna eget.</li>
                                                    <li>Quis mauris vel felis convallis nulla dignissim.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <RelatedProject/> */}
                                    {/* <Discuss/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};
export default ProjectSinglePage;