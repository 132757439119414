import React from 'react';
import { Link } from 'react-router-dom';
import Projects from '../../api/project'
import SectionTitle from '../SectionTitle';

import { ProjectI18n } from '../../i18n/project';
import { useParams } from "react-router-dom";
import { Service2I18n } from "../../i18n/service2";

import { ProjectsI18n } from '../../i18n/projects';


const ProjectSectionS2 = () => {


    const { lang = "ka" } = useParams();

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <div className="wpo-project-area-s2 section-padding">
            <div className="container">
                <SectionTitle subTitle={Service2I18n[lang]["text5"]} MainTitle={Service2I18n[lang]["text5"]}/>
                <div className="row align-items-center">
                    <div className="wpo-project-wrap">
                        <div className="sortable-gallery">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="project-grids gallery-container clearfix">
                                        {ProjectsI18n.map((pot) => (
                                            <div className="grid" key={pot.id}>
                                                <div className="wpo-project-item">
                                                    <div className="wpo-project-img">
                                                        <img src={pot.data[lang].pImg} alt="" style={{ aspectRatio: 422.45 / 316.83 }}/>
                                                        <div className="left-border"></div>
                                                        <div className="right-border"></div>
                                                    </div>
                                                    <div className="wpo-project-text">
                                                        <h2><Link onClick={ClickHandler} to={`/${lang}/completed/${pot.id}`}>{pot.data[lang].title}</Link></h2>
                                                        <span>{pot.data[lang].subTitle}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        ))}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default ProjectSectionS2;

