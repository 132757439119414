export const AboutI18n = {
  ka: {
    text1: `დეკოლაინი მომხმარებელს სთავაზობს მომსახურების სრულ პაკეტს`,

    text2: `სამშენებლო-სარემონტო კომპანია „დეკოლაინი“ 2016 წელს დაარსდა და ამ მიმართულებით მალევე იქცა სექტორის ლიდერად. კომპანია მუდმივად ორიენტირებულია ზრდაზე, განვითარებაზე და აქვს ამბიცია იკავებდეს ყველაზე დახვეწილი,
     ორგანიზებული, პროფესიონალებით დაკომპლექტებული გუნდის პოზიციას.  დეკოლაინი მომხმარებელს სთავაზობს მომსახურების სრულ პაკეტს: არქიტექტურა, დიზაინი, მშენებლობა და რემონტი/ექსპლუატაციაში ჩაბარება. დეკოლაინის პორტფელი მოიცავს ასობით კერძო საცხოვრებელი ბინის, ინდივიდუალური სახლების, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართების , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობას და რემონტს.`,

    text3: `მომსახურებისS სრულ პაკეტს: არქიტექტურა, დიზაინი, მშენებლობა და რემონტი/ექსპლუატაციაში ჩაბარება. დეკოლაინის პორტფელი მოიცავს ასობით კერძო საცხოვრებელი ბინის, ინდივიდუალური სახლების, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართების , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობას და რემონტს.`,
  
    text4:`აღმოაჩინე მეტი`,
  
    text5:`ჩვენს შესახებ`,
  
  
  },
  en: {
    text1: `Decoline offers customers a complete package of services `,

    text2: `The construction and repair company "Decoline" was founded in 2016 and soon became the leader of the sector in this direction. The company is constantly focused on growth, development and has the ambition to occupy the position of the most sophisticated, organized, professional team. Decoline offers customers a full package of services: architecture, design, construction and repair/Commissioning. Decoline's portfolio includes the construction and renovation of hundreds of private residential apartments, individual houses, office or production spaces of various companies, and multi-storey residential buildings.
    .`,

    text3: `Decoline offers customers  complete set  of services: architecture, design, construction and renovation/commissioning. Decoline's portfolio includes the construction and renovation of hundreds of private residential apartments, individual houses, office or production spaces of various companies,  construction and renovation of multi-storey residential buildings.
    `,


    text4:`Discover More`,

    text5:`About`,


  },
};
