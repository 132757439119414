import pimg1 from "../images/projects/1.jpg";
import pimg2 from "../images/projects/2.jpg";
import pimg3 from "../images/projects/3.jpg";
import pimg4 from "../images/projects/14.jpg";
import pimg5 from "../images/project-single/saremonto4 (1).jpg";
import pimg6 from "../images/projects/5.jpg";
import pimg7 from "../images/projects/6.jpg";
import pimg8 from "../images/projects/7.jpg";
import pimg9 from "../images/projects/8.jpg";
import pimg10 from "../images/projects/9.jpg";
import pimg11 from "../images/projects/10.jpg";
import pimg12 from "../images/projects/11.jpg";
import pimg13 from "../images/projects/12.jpg";
import pimg14 from "../images/projects/13.jpg";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/5.jpg";
import ps1img6 from "../images/project-single/6.jpg";
import ps1img7 from "../images/project-single/t1.jpg";
import ps1img8 from "../images/project-single/8.jpg";
import ps1img9 from "../images/project-single/9.jpg";
import ps1img10 from "../images/project-single/11.jpg";
import ps1img11 from "../images/project-single/10.jpg";
import ps1img12 from "../images/project-single/12.jpg";
import ps1img13 from "../images/project-single/13.jpg";
import ps1img14 from "../images/project-single/14.jpg";

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";
import psub1img3 from "../images/project-single/p3.jpg";
import psub1img4 from "../images/project-single/p4.jpg";

import tv1Img1 from "../images/project-single/t1.jpg";
import tv1Img2 from "../images/project-single/t2.jpg";
import tv1Img3 from "../images/project-single/t3.jpg";
import tv1Img4 from "../images/project-single/t4.jpg";

import subImg1 from "../images/project-single/s1.jpg";
import subImg2 from "../images/project-single/s2.jpg";
import subImg3 from "../images/project-single/s3.jpg";
import subImg4 from "../images/project-single/s4.jpg";

import remontiImg1 from "../images/project-single/saremonto1.jpg";
import remontiImg2 from "../images/project-single/saremonto2.jpg";
import remontiImg3 from "../images/project-single/saremonto3.jpg";
import remontiImg4 from "../images/project-single/saremonto4.jpg";

// --------------------------  არქიტექტურის ფოტოები

import a1img from "../images/architecture/a1.jpg";
import a2img from "../images/architecture/a2.jpg";
import s1img from "../images/architecture/s1.jpg";
import s2img from "../images/architecture/s2.jpg";
import s3img from "../images/architecture/s3.jpg";
import s4img from "../images/architecture/s4.jpg";

import b1img from "../images/architecture/b1.jpg";
import b2img from "../images/architecture/b2.jpg";

import m1img from "../images/architecture/m1.jpg";
import m2img from "../images/architecture/m2.jpg";
import m3img from "../images/architecture/m3.jpg";
import m4img from "../images/architecture/m4.jpg";
import m5img from "../images/architecture/m5.jpg";

import w1img from "../images/architecture/w1.jpg";
import w2img from "../images/architecture/w2.jpg";
import w3img from "../images/architecture/w3.jpg";
import w4img from "../images/architecture/w4.jpg";

import v1img from "../images/architecture/v1.jpg";
import v2img from "../images/architecture/v2.jpg";
import v3img from "../images/architecture/v3.jpg";
import v4img from "../images/architecture/v4.jpg";
import v5img from "../images/architecture/v5.jpg";
import v6img from "../images/architecture/v6.jpg";
import v7img from "../images/architecture/v7.jpg";
import v8img from "../images/architecture/v8.jpg";
import v9img from "../images/architecture/v9.jpg";

import q1img from "../images/architecture/q1.jpg";
import q2img from "../images/architecture/q2.jpg";
import q3img from "../images/architecture/q3.jpg";
import q4img from "../images/architecture/q4.jpg";
import q5img from "../images/architecture/q5.jpg";
import q6img from "../images/architecture/q6.jpg";

import r1img from "../images/architecture/r1.jpg";
import r2img from "../images/architecture/r2.jpg";
import r3img from "../images/architecture/r3.jpg";
import r4img from "../images/architecture/r4.jpg";


import t1img from "../images/architecture/t1.jpg";
import t2img from "../images/architecture/t2.jpg";
import t3img from "../images/architecture/t3.jpg";
import t4img from "../images/architecture/t4.jpg";
import t5img from "../images/architecture/t5.jpg";
import t6img from "../images/architecture/t6.jpg";


import g1img from "../images/architecture/g1.jpg";
import g2img from "../images/architecture/g2.jpg";
import g3img from "../images/architecture/g3.jpg";
import g4img from "../images/architecture/g4.jpg";

import h1img from "../images/architecture/h1.jpg";
import h2img from "../images/architecture/h2.jpg";
import h3img from "../images/architecture/h3.jpg";
import h4img from "../images/architecture/h4.jpg";


import k1img from "../images/architecture/k1.jpg";
import k2img from "../images/architecture/k2.jpg";
import k3img from "../images/architecture/k3.jpg";
import k4img from "../images/architecture/k4.jpg";
import k5img from "../images/architecture/k5.jpg";
import k6img from "../images/architecture/k6.jpg";
import k7img from "../images/architecture/k7.jpg";

import y1img from "../images/architecture/y1.png";
import y2img from "../images/architecture/y2.png";
import y3img from "../images/architecture/y3.png";
import y4img from "../images/architecture/y4.png";
import y5img from "../images/architecture/y5.png";


import ab1img from "../images/architecture/ab1.jpg";
import ab2img from "../images/architecture/ab2.jpg";
import ab3img from "../images/architecture/ab3.jpg";

import aa1img from "../images/architecture/aa1.jpg";
import aa2img from "../images/architecture/aa2.jpg";
import aa3img from "../images/architecture/aa3.jpg";
import aa4img from "../images/architecture/aa4.jpg";
import aa5img from "../images/architecture/aa5.jpg";


import ae1img from "../images/architecture/ae1.jpg";
import ae2img from "../images/architecture/ae1.jpg";
import ae3img from "../images/architecture/ae1.jpg";
import ae4img from "../images/architecture/ae1.jpg";
import ae5img from "../images/architecture/ae1.jpg";

import aq1img from "../images/architecture/aq1.jpg";
import aq2img from "../images/architecture/aq2.jpg";
import aq3img from "../images/architecture/aq3.jpg";
import aq4img from "../images/architecture/aq4.jpg";
import aq5img from "../images/architecture/aq5.jpg";


import aw1img from "../images/architecture/aw1.jpg";
import aw2img from "../images/architecture/aw2.jpg";
import aw3img from "../images/architecture/aw3.jpg";
import aw4img from "../images/architecture/aw4.jpg";
import aw5img from "../images/architecture/aw5.jpg";

import az1img from "../images/architecture/az1.jpg";
import az2img from "../images/architecture/az2.jpg";
import az3img from "../images/architecture/az3.jpg";
import az4img from "../images/architecture/az4.jpg";
import az5img from "../images/architecture/az5.jpg";
import az6img from "../images/architecture/az6.jpg";
import az7img from "../images/architecture/az7.jpg";


import ac1img from "../images/architecture/ac1.jpg";
import ac2img from "../images/architecture/ac2.jpg";

export const ProjectI18n={


    ka:[
        {
            Id:'1',
            pImg:pimg6,
            ps1img:ps1img6,
            psub1img1:psub1img1,
            psub1img2:psub1img2,
            psub1img3:psub1img3,
            psub1img4:psub1img4,
            title:'არქიტექტურა',
            subTitle:'დიზაინი',
            description: 'წავკისის ველი სამშენებლო სამუშაოები დასრულებული',
          },
          {
            Id:'2',
            pImg:pimg7,
            ps1img:ps1img7,
            psub1img1:tv1Img1,
            psub1img2:tv1Img2,
            psub1img3:tv1Img3,
            psub1img4:tv1Img4,
            title:'სარემონტო სამუშაოები',
            subTitle:'Interior Design',
            description: 'TV1 სარემონტო სამუშაოები',
          },
          {
            Id:'3',
            pImg:pimg3,
            ps1img:subImg1,
            psub1img1:subImg1,
            psub1img2:subImg2,
            psub1img3:subImg3,
            psub1img4:subImg4,
            title:'სარემონტო სამუშაოები',
            subTitle:'Interior Design',
            description: 'საბვეი გალერეა თბილისიში სარემონტო სამუშაოები',
         },
       
         {
            Id:'4',
            pImg:pimg5,
            ps1img:pimg5,
            psub1img1:remontiImg1,
            psub1img2:remontiImg2,
            psub1img3:remontiImg3,
            psub1img4:remontiImg4,
            title:'სარემონტო სამუშაოები',
            subTitle:'Interior Design',
            description: 'ასათიანი ქუჩა ბინის სარემონტო სამუშაოები',
         },

         {
            Id: "a1",
            pImg: a1img,
            ps1img: a1img,
            psub1img1: a1img,
            psub1img2: a2img,
        
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "ასათიანი ქუჩა ბინის სარემონტო სამუშაოები",
          },
        
        
          {
            Id: "a2",
            pImg: s1img,
            ps1img: s1img,
            psub1img1: s1img,
            psub1img2: s2img,
            psub1img3: s3img,
            psub1img4: s4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "კომერციული ფართი ქუთაისში",
          },
         
          {
            Id: "a3",
            pImg: b1img,
            ps1img: b1img,
            psub1img1: b1img,
            psub1img2: b2img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "სოფელი დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
          
        
          {
            Id: "a5",
            pImg: w1img,
            ps1img: w1img,
            psub1img1: w1img,
            psub1img2: w2img,
            psub1img3: w3img,
            psub1img4: w4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "წყნეთი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
         
        
        //   {
        //    Id: "a4",
        //    pImg: m4img,
        //    ps1img: m1img,
        //    psub1img1: m1img,
        //    psub1img2: m2img,
        //    psub1img3: m4img,
        //    psub1img4: m5img,
        //    psub1img5: m5img,
        
        //    title: "არქიტექტურა",
        //    subTitle: "დიზაინი",
        //    description: "მახათა ინდივიდუალურის საცხოვრებელი სახლი",
        //  },
        
          {
            Id: "a6",
            pImg: v1img,
            ps1img: v1img,
            psub1img1: v1img,
            psub1img2: v2img,
            psub1img3: v3img,
            psub1img4: v4img,
            psub1img5: v5img,
            psub1img6: v6img,
            psub1img7: v7img,
            psub1img8: v8img,
            psub1img9: v9img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "ბაკურიანი კოტეჯის ტიპის საცხოვრებელი სახლები",
          },
        
          {
            Id: "a7",
            pImg: q6img,
            ps1img: q6img,
            psub1img1: q1img,
            psub1img2: q2img,
            psub1img3: q3img,
            psub1img4: q4img,
            psub1img5: q5img,
            psub1img6: q6img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "თიანეთი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
          {
            Id: "a8",
            pImg: r1img,
            ps1img: r1img,
            psub1img1: r1img,
            psub1img2: r2img,
            psub1img3: r3img,
            psub1img4: r4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "დუშეთი-ბაზალეთის ტბა ინდივიდუალურის საცხოვრებელი სახლი",
          },
          {
           Id: "a9",
           pImg: t1img,
           ps1img: t1img,
           psub1img1: t1img,
           psub1img2: t2img,
           psub1img3: t3img,
           psub1img4: t4img,
           psub1img4: t5img,
           psub1img4: t6img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "კოტეჯის ტიპის საცხოვრებელი სახლი ლისზე",
         },
        
         {
           Id: "a10",
           pImg: g1img,
           ps1img: g1img,
           psub1img1: g1img,
           psub1img2: g2img,
           psub1img3: g3img,
           psub1img4: g4img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
         },
         {
           Id: "a11",
           pImg: h1img,
           ps1img: h1img,
           psub1img1: h1img,
           psub1img2: h2img,
           psub1img3: h3img,
           psub1img4: h4img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "მისაქციელი ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a12",
           pImg: k1img,
           ps1img: k1img,
           psub1img1: k1img,
           psub1img2: k2img,
           psub1img3: k3img,
           psub1img4: k4img,
           psub1img5: k5img,
           psub1img6: k6img,
           psub1img7: k7img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ჰოსპიტალი კომერციული ფართი",
         },
         {
           Id: "a13",
           pImg: y1img,
           ps1img: y1img,
           psub1img1: y1img,
           psub1img2: y2img,
           psub1img3: y3img,
           psub1img4: y4img,
           psub1img5: y5img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ქოშიგორა ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a14",
           pImg: ab1img,
           ps1img: ab1img,
           psub1img1: ab1img,
           psub1img2: ab2img,
           psub1img3: ab3img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ივერთუბანი ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a15",
           pImg: aa1img,
           ps1img: aa1img,
           psub1img1: aa1img,
           psub1img2: aa2img,
           psub1img3: aa3img,
           psub1img4: aa4img,
           psub1img5: aa5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "წოდორეთი ინდივიდუალურის საცხოვრებელი სახლი",
         },
         {
           Id: "a16",
           pImg: ae1img,
           ps1img: ae1img,
           psub1img1: ae1img,
           psub1img2: ae2img,
           psub1img3: ae3img,
           psub1img4: ae4img,
           psub1img5: ae5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "საგურამო ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
         {
           Id: "a17",
           pImg: aq1img,
           ps1img: aq1img,
           psub1img1: aq1img,
           psub1img2: aq2img,
           psub1img3: aq3img,
           psub1img4: aq4img,
           psub1img5: aq5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "თელავი სადეგუსტაციო კომერციული ფართი",
         },
        
        
         {
           Id: "a18",
           pImg: aw1img,
           ps1img: aw1img,
           psub1img1: aw1img,
           psub1img2: aw2img,
           psub1img3: aw3img,
           psub1img4: aw4img,
           psub1img5: aw5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
         },
        
         {
           Id: "a19",
           pImg: az1img,
           ps1img: az1img,
           psub1img1: az1img,
           psub1img2: az2img,
           psub1img3: az3img,
           psub1img4: az4img,
           psub1img5: az5img,
           psub1img6: az6img,
           psub1img7: az7img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
         },
        
         {
           Id: "a20",
           pImg: ac1img,
           ps1img: ac1img,
           psub1img1: ac1img,
           psub1img2: ac2img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ლისი ინდივიდუალური საცხოვრებელი სახლი",
         },
    ],







// english <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<





    en:[
        {
            Id:'1',
            pImg:pimg6,
            ps1img:ps1img6,
            psub1img1:psub1img1,
            psub1img2:psub1img2,
            psub1img3:psub1img3,
            psub1img4:psub1img4,
            title:'არქიტექტურაeng',
            subTitle:'დიზაინი',
            description: 'წავკისის ველი სამშენებლო სამუშაოები დასრულებულიeng',
          },
          {
            Id:'2',
            pImg:pimg7,
            ps1img:ps1img7,
            psub1img1:tv1Img1,
            psub1img2:tv1Img2,
            psub1img3:tv1Img3,
            psub1img4:tv1Img4,
            title:'სარემონტო სამუშაოებიeng',
            subTitle:'Interior Design',
            description: 'TV1 სარემონტო სამუშაოები',
          },
          {
            Id:'3',
            pImg:pimg3,
            ps1img:subImg1,
            psub1img1:subImg1,
            psub1img2:subImg2,
            psub1img3:subImg3,
            psub1img4:subImg4,
            title:'სარემონტო სამუშაოებიeng',
            subTitle:'Interior Design',
            description: 'საბვეი გალერეა თბილისიში სარემონტო სამუშაოები',
         },
       
         {
            Id:'4',
            pImg:pimg5,
            ps1img:pimg5,
            psub1img1:remontiImg1,
            psub1img2:remontiImg2,
            psub1img3:remontiImg3,
            psub1img4:remontiImg4,
            title:'სარემონტო სამუშაოები',
            subTitle:'Interior Design',
            description: 'ასათიანი ქუჩა ბინის სარემონტო სამუშაოები',
         },
         {
            Id: "a1",
            pImg: a1img,
            ps1img: a1img,
            psub1img1: a1img,
            psub1img2: a2img,
        
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "ასათიანი ქუჩა ბინის სარემონტო სამუშაოები",
          },
        
        
          {
            Id: "a2",
            pImg: s1img,
            ps1img: s1img,
            psub1img1: s1img,
            psub1img2: s2img,
            psub1img3: s3img,
            psub1img4: s4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "კომერციული ფართი ქუთაისში",
          },
         
          {
            Id: "a3",
            pImg: b1img,
            ps1img: b1img,
            psub1img1: b1img,
            psub1img2: b2img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "სოფელი დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
          
        
          {
            Id: "a5",
            pImg: w1img,
            ps1img: w1img,
            psub1img1: w1img,
            psub1img2: w2img,
            psub1img3: w3img,
            psub1img4: w4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "წყნეთი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
         
        
        //   {
        //    Id: "a4",
        //    pImg: m4img,
        //    ps1img: m1img,
        //    psub1img1: m1img,
        //    psub1img2: m2img,
        //    psub1img3: m4img,
        //    psub1img4: m5img,
        //    psub1img5: m5img,
        
        //    title: "არქიტექტურა",
        //    subTitle: "დიზაინი",
        //    description: "მახათა ინდივიდუალურის საცხოვრებელი სახლი",
        //  },
        
          {
            Id: "a6",
            pImg: v1img,
            ps1img: v1img,
            psub1img1: v1img,
            psub1img2: v2img,
            psub1img3: v3img,
            psub1img4: v4img,
            psub1img5: v5img,
            psub1img6: v6img,
            psub1img7: v7img,
            psub1img8: v8img,
            psub1img9: v9img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "ბაკურიანი კოტეჯის ტიპის საცხოვრებელი სახლები",
          },
        
          {
            Id: "a7",
            pImg: q6img,
            ps1img: q6img,
            psub1img1: q1img,
            psub1img2: q2img,
            psub1img3: q3img,
            psub1img4: q4img,
            psub1img5: q5img,
            psub1img6: q6img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "თიანეთი ინდივიდუალურის საცხოვრებელი სახლი",
          },
        
          {
            Id: "a8",
            pImg: r1img,
            ps1img: r1img,
            psub1img1: r1img,
            psub1img2: r2img,
            psub1img3: r3img,
            psub1img4: r4img,
            title: "არქიტექტურა",
            subTitle: "დიზაინი",
            description: "დუშეთი-ბაზალეთის ტბა ინდივიდუალურის საცხოვრებელი სახლი",
          },
          {
           Id: "a9",
           pImg: t1img,
           ps1img: t1img,
           psub1img1: t1img,
           psub1img2: t2img,
           psub1img3: t3img,
           psub1img4: t4img,
           psub1img4: t5img,
           psub1img4: t6img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "კოტეჯის ტიპის საცხოვრებელი სახლი ლისზე",
         },
        
         {
           Id: "a10",
           pImg: g1img,
           ps1img: g1img,
           psub1img1: g1img,
           psub1img2: g2img,
           psub1img3: g3img,
           psub1img4: g4img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
         },
         {
           Id: "a11",
           pImg: h1img,
           ps1img: h1img,
           psub1img1: h1img,
           psub1img2: h2img,
           psub1img3: h3img,
           psub1img4: h4img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "მისაქციელი ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a12",
           pImg: k1img,
           ps1img: k1img,
           psub1img1: k1img,
           psub1img2: k2img,
           psub1img3: k3img,
           psub1img4: k4img,
           psub1img5: k5img,
           psub1img6: k6img,
           psub1img7: k7img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ჰოსპიტალი კომერციული ფართი",
         },
         {
           Id: "a13",
           pImg: y1img,
           ps1img: y1img,
           psub1img1: y1img,
           psub1img2: y2img,
           psub1img3: y3img,
           psub1img4: y4img,
           psub1img5: y5img,
           
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ქოშიგორა ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a14",
           pImg: ab1img,
           ps1img: ab1img,
           psub1img1: ab1img,
           psub1img2: ab2img,
           psub1img3: ab3img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ივერთუბანი ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
        
         {
           Id: "a15",
           pImg: aa1img,
           ps1img: aa1img,
           psub1img1: aa1img,
           psub1img2: aa2img,
           psub1img3: aa3img,
           psub1img4: aa4img,
           psub1img5: aa5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "წოდორეთი ინდივიდუალურის საცხოვრებელი სახლი",
         },
         {
           Id: "a16",
           pImg: ae1img,
           ps1img: ae1img,
           psub1img1: ae1img,
           psub1img2: ae2img,
           psub1img3: ae3img,
           psub1img4: ae4img,
           psub1img5: ae5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "საგურამო ინდივიდუალურის საცხოვრებელი სახლი",
         },
        
         {
           Id: "a17",
           pImg: aq1img,
           ps1img: aq1img,
           psub1img1: aq1img,
           psub1img2: aq2img,
           psub1img3: aq3img,
           psub1img4: aq4img,
           psub1img5: aq5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "თელავი სადეგუსტაციო კომერციული ფართი",
         },
        
        
         {
           Id: "a18",
           pImg: aw1img,
           ps1img: aw1img,
           psub1img1: aw1img,
           psub1img2: aw2img,
           psub1img3: aw3img,
           psub1img4: aw4img,
           psub1img5: aw5img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
         },
        
         {
           Id: "a19",
           pImg: az1img,
           ps1img: az1img,
           psub1img1: az1img,
           psub1img2: az2img,
           psub1img3: az3img,
           psub1img4: az4img,
           psub1img5: az5img,
           psub1img6: az6img,
           psub1img7: az7img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
         },
        
         {
           Id: "a20",
           pImg: ac1img,
           ps1img: ac1img,
           psub1img1: ac1img,
           psub1img2: ac2img,
           title: "არქიტექტურა",
           subTitle: "დიზაინი",
           description: "ლისი ინდივიდუალური საცხოვრებელი სახლი",
         },

    ],


}