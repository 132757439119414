export const Service2I18n={


    ka:{
        text1:`დეტალურად`,
        text2:`სერვისები`,
        
        text3:`სათაური`,
        
        text4:`დაკავშირებული სერვისი`,
        text5:`დასრულებული სამუშაოები`,

        text6:`მიმდინარე სამუშაოები`,



    },
    en:{
        text1:`LEARN MORE`,
        text2:`SERVICE`,
        text3:`satauri`,
        text4:`Related Service`,
        text5:`Completed Works`,
        text6:`Current Works`,
    }


}