import React, { Component, useState } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';



import { useParams } from 'react-router-dom';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';




import { HeaderI18n } from '../../i18n/header';

import { MobileheaderI18n } from '../../i18n/mobileheader';



const menus = [

    
    {
        id: 1,
        title: {
            ka:"მთავარი" ,
            en: "Home"
        },
        link: '/home',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Home style 1',
        //         link: '/home'
        //     },
        //     {
        //         id: 12,
        //         title: 'Home style 2',
        //         link: '/home2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Home style 3',
        //         link: '/home3'
        //     },
        //     {
        //         id: 14,
        //         title: 'Home style 4',
        //         link: '/home4'
        //     },
        //     {
        //         id: 15,
        //         title: 'Home style 5',
        //         link: '/home5'
        //     },
        // ]
    },

    {
        id: 2,
        title: {
            ka:"ჩვენს შესახებ" ,
            en: "About"
        },
        link: '/about',
    },

{
    id: 3,
        title: {
            ka:"სერვისი" ,
            en: "Service"
        },
        link: '/service',
        // submenu: [
        //     // {
        //     //     id: 31,
        //     //     title: 'სერვისი',
        //     //     link: '/service'
        //     // },
        //     // {
        //     //     id: 32,
        //     //     title: 'სერვისი 2',
        //     //     link: '/service-s2'
        //     // },
        //     // {
        //     //     id: 33,
        //     //     title: 'არქიტექტურა',
        //     //     link: '/service-s2'
        //     // }
        // ]
    },
{
    id: 4,
        title: {
            ka:"პროექტი" ,
            en: "Project"
        },
        link: '#',
        submenu: [
            {
                id: 41,
                title: {
                    ka:"დასრულებული" ,
                    en: "Finished"
                },
                link: '/finished'
            },
            {
                id: 42,
                title: {
                    ka:"მიმდინარე" ,
                    en: "Current"
                },
                link: '/current'
            },
            // {
            //     id: 43,
            //     title: 'არქიტექტურა',
            //     link: '/project-single/1'
            // }
        ]
    },

    // {
    //     id: 7,
    //     title: 'Pages',
    //     link: '/',
    //     submenu: [
    //         {
    //             id: 771,
    //             title: 'Shop',
    //             link: '/shop'
    //         },
    //         {
    //             id: 772,
    //             title: 'Shop Single',
    //             link: '/product-single/1'
    //         },
    //         {
    //             id: 773,
    //             title: 'Cart',
    //             link: '/cart'
    //         },
    //         {
    //             id: 774,
    //             title: 'Checkout',
    //             link: '/checkout'
    //         },
    //         {
    //             id: 775,
    //             title: 'Team Single',
    //             link: '/team-single/1'
    //         },
    //         {
    //             id: 71,
    //             title: 'Pricing',
    //             link: '/pricing'
    //         },
    //         {
    //             id: 75,
    //             title: 'Testimonial',
    //             link: '/testimonial'
    //         },
    //         {
    //             id: 76,
    //             title: 'Error 404',
    //             link: '/404'
    //         },
    
    //         {
    //             id: 77,
    //             title: 'Login',
    //             link: '/login'
    //         },
    //         {
    //             id: 78,
    //             title: 'Register',
    //             link: '/register'
    //         },
            
    //     ]
    // },

    // {
    //     id: 5,
    //     title: 'Blog',
    //     link: '/blog',
    //     submenu: [
    //         {
    //             id: 51,
    //             title: 'Blog',
    //             link: '/blog'
    //         },
    //         {
    //             id: 52,
    //             title: 'Blog Left sidebar',
    //             link: '/blog-left'
    //         },
    //         {
    //             id: 53,
    //             title: 'Blog full width',
    //             link: '/blog-fullwidth'
    //         },
    //         {
    //             id: 54,
    //             title: 'Blog single',
    //             link: '/blog-details'
    //         },
    //         {
    //             id: 55,
    //             title: 'Blog single Left sidebar',
    //             link: '/blog-details-left'
    //         },
    //         {
    //             id: 56,
    //             title: 'Blog single Left sidebar',
    //             link: '/blog-details-fullwidth'
    //         },
    //     ]
    // },
    {
        id: 88,
        title: {
            ka:"კონტაქტი" ,
            en: "Contact"
        },
        link: '/contact',
    }
    
    
]
 //komment

export default class MobileMenu extends Component {

   constructor (props) 
        { 
                super(props);
                 console.log(props.lang);
        }

    

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        

        const ClickHandler = () =>{
            window.scrollTo(10, 0);
         }

        console.log(this.props);
        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                         <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>

                    <ul className="responsivemenu">
                        {menus.map(item => {
                           console.log(item)
                           return (
                                <li key={item.id}>
                                    {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                        {item.title[this.props.lang]}
                                        {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                    </p> : <Link onClick={ClickHandler} to={`/${this.props.lang}${item.link}` }>{item.title[this.props.lang]}</Link>}
                                    {item.submenu ?
                                    <Collapse isOpen={item.id === isOpen}>
                                        <Card>
                                            <CardBody>
                                                <ul>
                                                    {item.submenu.map(submenu => (
                                                        <li key={submenu.id}><Link onClick={ClickHandler} className="active" to={`/${this.props.lang}${submenu.link}` }>{submenu.title[this.props.lang]}</Link></li>
                                                    ))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                    : ''}
                                </li>
                            )
                        })}
                    </ul>

                </div>

                <div className="showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                            <span className="icon-bar first-angle"></span>
                            <span className="icon-bar middle-angle"></span>
                            <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
        )
    }
}
