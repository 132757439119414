import React from "react";
import { Link } from 'react-router-dom'
// import himg from '../../images/slider/s1.jpg'
import himg from '../../images/resize1.png'
import RandomImage from "./randomImg";
import { useParams } from 'react-router-dom';
import { HeroI18n } from "../../i18n/hero";








const Hero =() => {

    const {lang='ka'} =useParams()

    return (
        <section className="static-hero">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-7 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div data-swiper-parallax="200" className="slide-visible-text">
                                        <h2>{
                                                HeroI18n[lang]['text5']
                                             } </h2>
                                    </div>
                                    <div data-swiper-parallax="300" className="slide-title">
                                        {/* <h3>მოიწყე შენი სასურველი გარემო 
                                            <span>კომფორტული ცხოვრებისათვის. </span> 
                                            <span>პრემიუმ ხარისხის 
                                            მომსახურება სამშენებლო-სარემონტო ინდუსტრიაში. </span>
                                            </h3> */}
                                          
                                            
                                           
                                           
                                            <h1 >
                                             {
                                                HeroI18n[lang]['text1']
                                             }   
                                              </h1>

                                             <div >
                                             <h3><span>
                                                <p >
                                                {
                                                    HeroI18n[lang]['text2']
                                                }
                                                </p>
                                                
                                                </span></h3>
                                             </div>
                                            



                                                            {/* როდესაც html გვაქვს  */}
                                                {/* <h3 dangerouslySetInnerHTML={{__html:HeroI18n[lang]['text3']}}/> */}
                                                


                                    </div>
                                    <div data-swiper-parallax="400" className="slide-btn">
                                        <Link className="theme-btn" to={`/${lang}/finished/`}>{
                                                    HeroI18n[lang]['text4']
                                                }</Link>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="static-hero-right">
                <div className="static-hero-img">
                    <div className="static-hero-img-inner">
                        {/* <img src={himg} alt="eror"/> */}
                         < RandomImage />
                    </div>
                </div>
            </div>





        </section>
    )
}

export default Hero;