import pimg1 from "../images/projects/1.jpg";
import pimg2 from "../images/projects/2.jpg";
import pimg3 from "../images/projects/3.jpg";
import pimg4 from "../images/projects/14.jpg";
import pimg5 from "../images/project-single/saremonto4 (1).jpg";
import pimg6 from "../images/projects/5.jpg";
import pimg7 from "../images/projects/6.jpg";
import pimg8 from "../images/projects/7.jpg";
import pimg9 from "../images/projects/8.jpg";
import pimg10 from "../images/projects/9.jpg";
import pimg11 from "../images/projects/10.jpg";
import pimg12 from "../images/projects/11.jpg";
import pimg13 from "../images/projects/12.jpg";
import pimg14 from "../images/projects/13.jpg";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/5.jpg";
import ps1img6 from "../images/project-single/6.jpg";
import ps1img7 from "../images/project-single/t1.jpg";
import ps1img8 from "../images/project-single/8.jpg";
import ps1img9 from "../images/project-single/9.jpg";
import ps1img10 from "../images/project-single/11.jpg";
import ps1img11 from "../images/project-single/10.jpg";
import ps1img12 from "../images/project-single/12.jpg";
import ps1img13 from "../images/project-single/13.jpg";
import ps1img14 from "../images/project-single/14.jpg";

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";
import psub1img3 from "../images/project-single/p3.jpg";
import psub1img4 from "../images/project-single/p4.jpg";

import tv1Img1 from "../images/project-single/t1.jpg";
import tv1Img2 from "../images/project-single/t2.jpg";
import tv1Img3 from "../images/project-single/t3.jpg";
import tv1Img4 from "../images/project-single/t4.jpg";

import subImg1 from "../images/project-single/s1.jpg";
import subImg2 from "../images/project-single/s2.jpg";
import subImg3 from "../images/project-single/s3.jpg";
import subImg4 from "../images/project-single/s4.jpg";

import remontiImg1 from "../images/project-single/saremonto1.jpg";
import remontiImg2 from "../images/project-single/saremonto2.jpg";
import remontiImg3 from "../images/project-single/saremonto3.jpg";
import remontiImg4 from "../images/project-single/saremonto4.jpg";

// --------------------------  არქიტექტურის ფოტოები

import a1img from "../images/architecture/a1.jpg";
import a2img from "../images/architecture/a2.jpg";
import s1img from "../images/architecture/s1.jpg";
import s2img from "../images/architecture/s2.jpg";
import s3img from "../images/architecture/s3.jpg";
import s4img from "../images/architecture/s4.jpg";

import b1img from "../images/architecture/b1.jpg";
import b2img from "../images/architecture/b2.jpg";

import m1img from "../images/architecture/m1.jpg";
import m2img from "../images/architecture/m2.jpg";
import m3img from "../images/architecture/m3.jpg";
import m4img from "../images/architecture/m4.jpg";
import m5img from "../images/architecture/m5.jpg";

import w1img from "../images/architecture/w1.jpg";
import w2img from "../images/architecture/w2.jpg";
import w3img from "../images/architecture/w3.jpg";
import w4img from "../images/architecture/w4.jpg";

import v1img from "../images/architecture/v1.jpg";
import v2img from "../images/architecture/v2.jpg";
import v3img from "../images/architecture/v3.jpg";
import v4img from "../images/architecture/v4.jpg";
import v5img from "../images/architecture/v5.jpg";
import v6img from "../images/architecture/v6.jpg";
import v7img from "../images/architecture/v7.jpg";
import v8img from "../images/architecture/v8.jpg";
import v9img from "../images/architecture/v9.jpg";

import q1img from "../images/architecture/q1.jpg";
import q2img from "../images/architecture/q2.jpg";
import q3img from "../images/architecture/q3.jpg";
import q4img from "../images/architecture/q4.jpg";
import q5img from "../images/architecture/q5.jpg";
import q6img from "../images/architecture/q6.jpg";

import r1img from "../images/architecture/r1.jpg";
import r2img from "../images/architecture/r2.jpg";
import r3img from "../images/architecture/r3.jpg";
import r4img from "../images/architecture/r4.jpg";


import t1img from "../images/architecture/t1.jpg";
import t2img from "../images/architecture/t2.jpg";
import t3img from "../images/architecture/t3.jpg";
import t4img from "../images/architecture/t4.jpg";
import t5img from "../images/architecture/t5.jpg";
import t6img from "../images/architecture/t6.jpg";


import g1img from "../images/architecture/g1.jpg";
import g2img from "../images/architecture/g2.jpg";
import g3img from "../images/architecture/g3.jpg";
import g4img from "../images/architecture/g4.jpg";

import h1img from "../images/architecture/h1.jpg";
import h2img from "../images/architecture/h2.jpg";
import h3img from "../images/architecture/h3.jpg";
import h4img from "../images/architecture/h4.jpg";


import k1img from "../images/architecture/k1.jpg";
import k2img from "../images/architecture/k2.jpg";
import k3img from "../images/architecture/k3.jpg";
import k4img from "../images/architecture/k4.jpg";
import k5img from "../images/architecture/k5.jpg";
import k6img from "../images/architecture/k6.jpg";
import k7img from "../images/architecture/k7.jpg";

import y1img from "../images/architecture/y1.png";
import y2img from "../images/architecture/y2.png";
import y3img from "../images/architecture/y3.png";
import y4img from "../images/architecture/y4.png";
import y5img from "../images/architecture/y5.png";


import ab1img from "../images/architecture/ab1.jpg";
import ab2img from "../images/architecture/ab2.jpg";
import ab3img from "../images/architecture/ab3.jpg";

import aa1img from "../images/architecture/aa1.jpg";
import aa2img from "../images/architecture/aa2.jpg";
import aa3img from "../images/architecture/aa3.jpg";
import aa4img from "../images/architecture/aa4.jpg";
import aa5img from "../images/architecture/aa5.jpg";


import ae1img from "../images/architecture/ae1.jpg";
import ae2img from "../images/architecture/ae1.jpg";
import ae3img from "../images/architecture/ae1.jpg";
import ae4img from "../images/architecture/ae1.jpg";
import ae5img from "../images/architecture/ae1.jpg";

import aq1img from "../images/architecture/aq1.jpg";
import aq2img from "../images/architecture/aq2.jpg";
import aq3img from "../images/architecture/aq3.jpg";
import aq4img from "../images/architecture/aq4.jpg";
import aq5img from "../images/architecture/aq5.jpg";


import aw1img from "../images/architecture/aw1.jpg";
import aw2img from "../images/architecture/aw2.jpg";
import aw3img from "../images/architecture/aw3.jpg";
import aw4img from "../images/architecture/aw4.jpg";
import aw5img from "../images/architecture/aw5.jpg";

import az1img from "../images/architecture/az1.jpg";
import az2img from "../images/architecture/az2.jpg";
import az3img from "../images/architecture/az3.jpg";
import az4img from "../images/architecture/az4.jpg";
import az5img from "../images/architecture/az5.jpg";
import az6img from "../images/architecture/az6.jpg";
import az7img from "../images/architecture/az7.jpg";


import ac1img from "../images/architecture/ac1.jpg";
import ac2img from "../images/architecture/ac2.jpg";


const Projects = [
   {
     Id:'1',
     pImg:pimg6,
     ps1img:ps1img6,
     psub1img1:psub1img1,
     psub1img2:psub1img2,
     psub1img3:psub1img3,
     psub1img4:psub1img4,
     title:'არქიტექტურა',
     subTitle:'დიზაინი',
     description: 'წავკისის ველი სამშენებლო სამუშაოები დასრულებული',
   },
   {
     Id:'2',
     pImg:pimg7,
     ps1img:ps1img7,
     psub1img1:tv1Img1,
     psub1img2:tv1Img2,
     psub1img3:tv1Img3,
     psub1img4:tv1Img4,
     title:'სარემონტო სამუშაოები',
     subTitle:'Interior Design',
     description: 'TV1 სარემონტო სამუშაოები',
   },
   {
     Id:'3',
     pImg:pimg3,
     ps1img:subImg1,
     psub1img1:subImg1,
     psub1img2:subImg2,
     psub1img3:subImg3,
     psub1img4:subImg4,
     title:'სარემონტო სამუშაოები',
     subTitle:'Interior Design',
     description: 'საბვეი გალერეა თბილისიში სარემონტო სამუშაოები',
  },

  {
     Id:'4',
     pImg:pimg5,
     ps1img:pimg5,
     psub1img1:remontiImg1,
     psub1img2:remontiImg2,
     psub1img3:remontiImg3,
     psub1img4:remontiImg4,
     title:'სარემონტო სამუშაოები',
     subTitle:'Interior Design',
     description: 'ასათიანი ქუჩა ბინის სარემონტო სამუშაოები',
  },

  //------------------------- არქიტექტურის ჩამონათვალი
 
  





 


];

export default Projects;
