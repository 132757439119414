
import React, { useState, useEffect } from "react";
import image1 from '../../images/resize1.png'
import image2 from '../../images/resize2.jpg'
import image3 from '../../images/s2.png'
import image4 from '../../images/s11.png'

function RandomImage() {
  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    const images = [image1, image3];
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  }, []);

  return (
    <div>
      {/* {randomImage && <img src={randomImage} alt="random image" />} */}
      {randomImage && <img src={image4} alt="random image" />}
    </div>
  );
}

export default RandomImage;
