import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero';
// import About from '../../components/about';
import ProjectSection from '../../components/Projects';
import ServiceSection from '../../components/Services';
import FunFact from '../../components/FunFact';
import TeamSection from '../../components/TeamSection';
import Testimonial from '../../components/Testimonial';
import BlogSection from '../../components/BlogSection';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/decoline.logo.png'
import abimg from '../../images/about.jpg'
import { useParams } from 'react-router-dom';
import About4 from '../../components/about4'
import AboutSinglePage from '../../components/AboutSingle';


const HomePage =() => {

    const {lang='ka'} =useParams()
    console.log(lang)
    return(
        <Fragment>
            <Navbar Logo={Logo} hclass={'header-style-1'}/>
            <Hero/>
            <AboutSinglePage />
            {/* <About abimg={abimg}/> */}
            <ProjectSection/>
            
            <ServiceSection/>
            {/* <FunFact/>
            <TeamSection/>
            <Testimonial/>   
            <BlogSection/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;