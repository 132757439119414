export const ContactI18n={
    ka:{    
        text1:`მისამართი `,
        text2:` ქ.თბილისი დ.თავხელიძის ქ #1ა `,
        
        text4:`შეტყობინება `,
        text5:`დაგვიკავშირდით `,
        text6:`კონტაქტი`,
    },
    en:{
        text1:`Address `,
        text2:` 1 Davit Tavkhelidze St, Tbilisi `,
        
        text4:`Email Us `,
        text5:`Call Now `,
        text6:`Contact`,
    }
}