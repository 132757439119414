export const HeaderI18n={
    ka:{
        text1:`მთავარი `,

        text2:`ჩვენს შესახებ `,

        text3:`სერვისები`,

        text4:`პროექტი`,
        text5:`დასრულებული`,
        text6:`მიმდინარე`,

        text7:`კონტაქტი`,
        
    },
    en:{
        text1:`Home  `,

        text2:`About`,

        text3:`Service`,

        text4:`Project`,
        text5:`Completed`,
        text6:`Current`,

        text7:`Contact`,
    }
}