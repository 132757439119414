export const HeroI18n={
    ka:{
        text1:`პრემიუმ ხარისხის მომსახურეობა `,
        text2:`სამშენებლო-სარემონტო ინდუსტრია.`,
        text3:`<span>პრემიუმ ხარისხის 
        მომსახურება სამშენებლო-სარემონტო ინდუსტრიაში.</span>`,
        text4:`პროექტები`,
        text5:`ყველგან სადაც მშენებლობაა`,
        
    },
    en:{
        text1:`Premium quality service `,
        text2:`building and renovation industry`,   
        text3:`Premium quality services in the construction and Renovation industry.`,
        text4:`Discover Projects`,
        text5:`Where there's construction`,
    }
}