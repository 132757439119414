import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";
import SectionTitle from "../SectionTitle";
import { useParams } from "react-router-dom";
import { ServiceI18n } from "../../i18n/service";
import { Service2I18n } from "../../i18n/service2";
import { CenterFocusStrong } from "@material-ui/icons";

const ServiceSection = (props) => {
  const { lang = "ka" } = useParams();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`wpo-service-area section-padding ${props.sClass}`}>
      <div className="container">
        <SectionTitle subTitle={Service2I18n[lang]['text2']} />
        <div className="row align-items-center">
          {ServiceI18n.map((sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={sitem.id}>
              <div
                className="wpo-service-item"
                style={{ aspectRatio: 1, padding: 0 }}
              >
                <img src={sitem.data[lang].sImg} alt="" />
                <div className="padding20">
                  <h2>{sitem.data[lang].sTitle}</h2>
                  
                  <p className="wrap-text">{sitem.data[lang].description}</p>
                </div>
                <div className="service-links" style={ {  } } >
                    <Link onClick={ClickHandler}to={`/${lang}/service-single/${sitem.id}`}> {Service2I18n[lang]["text1"]} </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
