import React from 'react'
import { Link } from 'react-router-dom'


import videoBg from '../../images/GEORG.mp4'

// const PageTitle = (props) => {
//     return(
//         <section className="wpo-page-title">
//             <div className="container">
//                 <div className="row">
//                     <div className="col col-xs-12">
//                         <div className="wpo-breadcumb-wrap">
//                             <h2>{props.pageTitle}</h2>
//                             <ol className="wpo-breadcumb-wrap">
//                                 <li><Link to="/">Home</Link></li>
//                                 <li><span>{props.pagesub}</span></li>
//                             </ol>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default PageTitle;







const PageTitle = (props) => {

    return(

        <div className='videoBg-main'>
            <video src={videoBg} autoPlay loop muted />
        </div>


    )




}


export default PageTitle;