import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import RelatedService from "./related";
import Discuss from "./discuss";
import ServiceSidebar from "./sidebar";
import Logo from '../../images/decoline.logo.png';
import Footer from "../../components/footer";

import { ServiceI18n } from "../../i18n/service";

import { Link } from 'react-router-dom'


const ServiceSinglePage = (props) => {
  const { id } = useParams();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
}

  const { lang = "ka" } = useParams();  

  const serviceDetails = ServiceI18n.find((item) => item.id === id);
   
  console.log(serviceDetails?.data["en"] ,  ServiceI18n, id);

  

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      {/* <PageTitle pageTitle={serviceDetails.sTitle} pagesub={"Project"} /> */}
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
              
              <div className="wpo-service-single-item">
                
                    <div >
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails?.data[lang].sImg} alt="" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails?.data[lang].sTitle}</h3>
                  </div>
                  <p>
                  {serviceDetails?.data[lang].des2}
                  </p>
                  <p>
                  {serviceDetails?.data[lang].des3}
                  </p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/2.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                    </div>
           


                
                    
                   




















              </div>



                  






                {/* koment */}

                {/* <div className="wpo-service-single-item">

                

                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.sImg} alt="" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{Service2I18n[lang]["text3"]}</h3>
                  </div>
                  <p>
                  {serviceDetails.des2}
                  </p>
                  <p>
                  {serviceDetails.des3}
                  </p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/2.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                  
                </div> */}




            




                {/* <div className="wpo-service-single-item2">
                  {ServiceI18n[lang].map((service, sitem) => (
                    <div>
                      <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.sImg} alt="" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{service.sTitle}</h3>
                  </div>
                  <p>
                  {service.des2}
                  </p>
                  <p>
                  {service.des3}
                  </p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/2.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src="assets/images/service-single/3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                    </div>
                  ))}
                </div> */}






                {/* <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Capabilities</h3>
                  </div>
                  <p>
                    Massa volutpat odio facilisis purus sit elementum. Non, sed
                    velit dictum quam. Id risus pharetra est, at rhoncus, nec
                    ullamcorper tincidunt. Id aliquet duis sollicitudin diam.
                  </p>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                    <li>Elit curabitur amet risus bibendum.</li>
                  </ul>
                </div> */}
                {/* <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Consequat suspendisse aenean tellus augue morbi risus. Sit
                    morbi vitae morbi sed urna sed purus. Orci facilisi eros sed
                    pellentesque. Risus id sed tortor sed scelerisque.
                    Vestibulum elit elementum, magna id viverra non, velit.
                    Pretium, eros, porttitor fusce auctor vitae id. Phasellus
                    scelerisque nibh eleifend vel enim mauris purus. Rutrum vel
                    sem adipiscing nisi vulputate molestie scelerisque molestie
                    ultrices. Eu, fusce vulputate diam interdum morbi ac a.
                  </p>
                </div> */}
                {/* <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                  </ul>
                </div> */}
                <RelatedService />
                {/* <Discuss /> */}
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
