import pimg1 from "../images/projects/1.jpg";
import pimg2 from "../images/projects/2.jpg";
import pimg3 from "../images/projects/3.jpg";
import pimg4 from "../images/projects/14.jpg";
import pimg5 from "../images/project-single/saremonto4 (1).jpg";
import pimg6 from "../images/projects/5.jpg";
import pimg7 from "../images/projects/6.jpg";
import pimg8 from "../images/projects/7.jpg";
import pimg9 from "../images/projects/8.jpg";
import pimg10 from "../images/projects/9.jpg";
import pimg11 from "../images/projects/10.jpg";
import pimg12 from "../images/projects/11.jpg";
import pimg13 from "../images/projects/12.jpg";
import pimg14 from "../images/projects/13.jpg";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/5.jpg";
import ps1img6 from "../images/project-single/6.jpg";
import ps1img7 from "../images/project-single/t1.jpg";
import ps1img8 from "../images/project-single/8.jpg";
import ps1img9 from "../images/project-single/9.jpg";
import ps1img10 from "../images/project-single/11.jpg";
import ps1img11 from "../images/project-single/10.jpg";
import ps1img12 from "../images/project-single/12.jpg";
import ps1img13 from "../images/project-single/13.jpg";
import ps1img14 from "../images/project-single/14.jpg";

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";
import psub1img3 from "../images/project-single/p3.jpg";
import psub1img4 from "../images/project-single/p4.jpg";

import tv1Img1 from "../images/project-single/t1.jpg";
import tv1Img2 from "../images/project-single/t2.jpg";
import tv1Img3 from "../images/project-single/t3.jpg";
import tv1Img4 from "../images/project-single/t4.jpg";

import subImg1 from "../images/project-single/s1.jpg";
import subImg2 from "../images/project-single/s2.jpg";
import subImg3 from "../images/project-single/s3.jpg";
import subImg4 from "../images/project-single/s4.jpg";

import remontiImg1 from "../images/project-single/saremonto1.jpg";
import remontiImg2 from "../images/project-single/saremonto2.jpg";
import remontiImg3 from "../images/project-single/saremonto3.jpg";
import remontiImg4 from "../images/project-single/saremonto4.jpg";

// --------------------------  არქიტექტურის ფოტოები

import a1img from "../images/architecture/a1.jpg";
import a2img from "../images/architecture/a2.jpg";
import s1img from "../images/architecture/s1.jpg";
import s2img from "../images/architecture/s2.jpg";
import s3img from "../images/architecture/s3.jpg";
import s4img from "../images/architecture/s4.jpg";

import b1img from "../images/architecture/b1.jpg";
import b2img from "../images/architecture/b2.jpg";

import m1img from "../images/architecture/m1.jpg";
import m2img from "../images/architecture/m2.jpg";
import m3img from "../images/architecture/m3.jpg";
import m4img from "../images/architecture/m4.jpg";
import m5img from "../images/architecture/m5.jpg";

import w1img from "../images/architecture/w1.jpg";
import w2img from "../images/architecture/w2.jpg";
import w3img from "../images/architecture/w3.jpg";
import w4img from "../images/architecture/w4.jpg";

import v1img from "../images/architecture/v1.jpg";
import v2img from "../images/architecture/v2.jpg";
import v3img from "../images/architecture/v3.jpg";
import v4img from "../images/architecture/v4.jpg";
import v5img from "../images/architecture/v5.jpg";
import v6img from "../images/architecture/v6.jpg";
import v7img from "../images/architecture/v7.jpg";
import v8img from "../images/architecture/v8.jpg";
import v9img from "../images/architecture/v9.jpg";

import q1img from "../images/architecture/q1.jpg";
import q2img from "../images/architecture/q2.jpg";
import q3img from "../images/architecture/q3.jpg";
import q4img from "../images/architecture/q4.jpg";
import q5img from "../images/architecture/q5.jpg";
import q6img from "../images/architecture/q6.jpg";

import r1img from "../images/architecture/r1.jpg";
import r2img from "../images/architecture/r2.jpg";
import r3img from "../images/architecture/r3.jpg";
import r4img from "../images/architecture/r4.jpg";

import t1img from "../images/architecture/t1.jpg";
import t2img from "../images/architecture/t2.jpg";
import t3img from "../images/architecture/t3.jpg";
import t4img from "../images/architecture/t4.jpg";
import t5img from "../images/architecture/t5.jpg";
import t6img from "../images/architecture/t6.jpg";

import g1img from "../images/architecture/g1.jpg";
import g2img from "../images/architecture/g2.jpg";
import g3img from "../images/architecture/g3.jpg";
import g4img from "../images/architecture/g4.jpg";

import h1img from "../images/architecture/h1.jpg";
import h2img from "../images/architecture/h2.jpg";
import h3img from "../images/architecture/h3.jpg";
import h4img from "../images/architecture/h4.jpg";

import k1img from "../images/architecture/k1.jpg";
import k2img from "../images/architecture/k2.jpg";
import k3img from "../images/architecture/k3.jpg";
import k4img from "../images/architecture/k4.jpg";
import k5img from "../images/architecture/k5.jpg";
import k6img from "../images/architecture/k6.jpg";
import k7img from "../images/architecture/k7.jpg";

import y1img from "../images/architecture/y1.png";
import y2img from "../images/architecture/y2.png";
import y3img from "../images/architecture/y3.png";
import y4img from "../images/architecture/y4.png";
import y5img from "../images/architecture/y5.png";

import ab1img from "../images/architecture/ab1.jpg";
import ab2img from "../images/architecture/ab2.jpg";
import ab3img from "../images/architecture/ab3.jpg";

import aa1img from "../images/architecture/aa1.jpg";
import aa2img from "../images/architecture/aa2.jpg";
import aa3img from "../images/architecture/aa3.jpg";
import aa4img from "../images/architecture/aa4.jpg";
import aa5img from "../images/architecture/aa5.jpg";

import ae1img from "../images/architecture/ae1.jpg";
import ae2img from "../images/architecture/ae1.jpg";
import ae3img from "../images/architecture/ae1.jpg";
import ae4img from "../images/architecture/ae1.jpg";
import ae5img from "../images/architecture/ae1.jpg";

import aq1img from "../images/architecture/aq1.jpg";
import aq2img from "../images/architecture/aq2.jpg";
import aq3img from "../images/architecture/aq3.jpg";
import aq4img from "../images/architecture/aq4.jpg";
import aq5img from "../images/architecture/aq5.jpg";

import aw1img from "../images/architecture/aw1.jpg";
import aw2img from "../images/architecture/aw2.jpg";
import aw3img from "../images/architecture/aw3.jpg";
import aw4img from "../images/architecture/aw4.jpg";
import aw5img from "../images/architecture/aw5.jpg";

import az1img from "../images/architecture/az1.jpg";
import az2img from "../images/architecture/az2.jpg";
import az3img from "../images/architecture/az3.jpg";
import az4img from "../images/architecture/az4.jpg";
import az5img from "../images/architecture/az5.jpg";
import az6img from "../images/architecture/az6.jpg";
import az7img from "../images/architecture/az7.jpg";

import ac1img from "../images/architecture/ac1.jpg";
import ac2img from "../images/architecture/ac2.jpg";


import ar1img from "../images/architecture/ar1.jpg";
import ar2img from "../images/architecture/ar2.jpg";
import ar3img from "../images/architecture/ar3.jpg";
import ar4img from "../images/architecture/ar4.jpg";
import ar5img from "../images/architecture/ar5.jpg";

import ay1img from "../images/architecture/ay1.jpg";
import ay2img from "../images/architecture/ay2.jpg";
import ay3img from "../images/architecture/ay3.jpg";
import ay4img from "../images/architecture/ay4.jpg";

import av1img from "../images/architecture/av1.jpg";
import av2img from "../images/architecture/av2.jpg";
import av3img from "../images/architecture/av3.jpg";
import av4img from "../images/architecture/av4.jpg";


















export const ProjectsI18n = [



  {
    id: "1",

    data: {
      ka: {
        pImg: pimg6,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        psub1img3: psub1img3,
        psub1img4: psub1img4,
        title: "მშენებლობა",
        subTitle: "მშენებლობა",
        description: "წავკისის ველი სამშენებლო სამუშაოები დასრულებული",


        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "მშენებლობა",
        duration: "",
        completion: "",



      },
      en: {
        pImg: pimg6,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        psub1img3: psub1img3,
        psub1img4: psub1img4,
        title: "Construction",
        subTitle: "Construction",
        description: "Tsavkis Valley construction works completed",


        location: "Tbilisi",
        client: "",
        architect: "",
        projectType: "Construction",
        duration: "",
        completion: "",
      },
    },
  },

  {
    id: "2",

    data: {
      ka: {
        pImg: pimg7,
        ps1img: ps1img7,
        psub1img1: tv1Img1,
        psub1img2: tv1Img2,
        psub1img3: tv1Img3,
        psub1img4: tv1Img4,
        title: "სარემონტო სამუშაოები",
        subTitle: "დიზაინი",
        description: "TV1 სარემონტო სამუშაოები",


        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "სარემონტო სამუშაოები",
        duration: "",
        completion: "",
      },
      en: {
        pImg: pimg7,
        ps1img: ps1img7,
        psub1img1: tv1Img1,
        psub1img2: tv1Img2,
        psub1img3: tv1Img3,
        psub1img4: tv1Img4,
        title: "Repair works",
        subTitle: "Repair works",
        description: "TV1 Repair works",



        location: "Tbilisi",
        client: "",
        architect: "",
        projectType: "Repair works",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "3",

    data: {
      ka: {
        pImg: pimg3,
        ps1img: subImg1,
        psub1img1: subImg1,
        psub1img2: subImg2,
        // psub1img3: subImg3,
        // psub1img4: subImg4,
        title: "სარემონტო სამუშაოები",
        subTitle: "ინტერიერის დიზაინი",
        description: "საბვეი გალერეა თბილისიში სარემონტო სამუშაოები",


        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: pimg3,
        ps1img: subImg1,
        psub1img1: subImg1,
        psub1img2: subImg2,
        // psub1img3: subImg3,
        // psub1img4: subImg4,
        title: "Repair works",
        subTitle: "Repair works",
        description: "Subway Gallery in Tbilisi, renovation works",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },

  {
    id: "4",

    data: {
      ka: {
        pImg: pimg5,
        ps1img: pimg5,
        psub1img1: remontiImg1,
        psub1img2: remontiImg2,
        psub1img3: remontiImg3,
        psub1img4: remontiImg4,
        title: "სარემონტო სამუშაოები",
        subTitle: "სარემონტო სამუშაოები",
        description: "ასათიანი ქუჩა ბინის სარემონტო სამუშაოები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "სარემონტო სამუშაოები",
        duration: "",
        completion: "",
      },
      en: {
        pImg: pimg5,
        ps1img: pimg5,
        psub1img1: remontiImg1,
        psub1img2: remontiImg2,
        psub1img3: remontiImg3,
        psub1img4: remontiImg4,
        title: "Repair works",
        subTitle: "Repair works",
        description: "ასათიანი ქუჩა ბინის სარემონტო სამუშაოები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "Repair works",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a1",

    data: {
      ka: {
        pImg: a1img,
        ps1img: a1img,
        psub1img1: a1img,
        psub1img2: a2img,

        title: "პროექტი",
        subTitle: "პროექტი",
        description: "",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: a1img,
        ps1img: a1img,
        psub1img1: a1img,
        psub1img2: a2img,

        title: "Project",
        subTitle: "Project",
        description: "",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a2",

    data: {
      ka: {
        pImg: s1img,
        ps1img: s1img,
        psub1img1: s1img,
        psub1img2: s2img,
        psub1img3: s3img,
        psub1img4: s4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "კომერციული ფართი ქუთაისში",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: s1img,
        ps1img: s1img,
        psub1img1: s1img,
        psub1img2: s2img,
        psub1img3: s3img,
        psub1img4: s4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "კომერციული ფართი ქუთაისში",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a3",

    data: {
      ka: {
        pImg: b1img,
        ps1img: b1img,
        psub1img1: b1img,
        psub1img2: b2img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "სოფელი დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: b1img,
        ps1img: b1img,
        psub1img1: b1img,
        psub1img2: b2img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "სოფელი დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a4",

    data: {
      ka: {
        pImg: m4img,
        ps1img: m1img,
        psub1img1: m1img,
        psub1img2: m2img,
        psub1img3: m4img,
        psub1img4: m5img,
        psub1img5: m5img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "მახათა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: m4img,
        ps1img: m1img,
        psub1img1: m1img,
        psub1img2: m2img,
        psub1img3: m4img,
        psub1img4: m5img,
        psub1img5: m5img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "მახათა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a5",

    data: {
      ka: {
        pImg: w1img,
        ps1img: w1img,
        psub1img1: w1img,
        psub1img2: w2img,
        psub1img3: w3img,
        psub1img4: w4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "წყნეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: w1img,
        ps1img: w1img,
        psub1img1: w1img,
        psub1img2: w2img,
        psub1img3: w3img,
        psub1img4: w4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "წყნეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a6",

    data: {
      ka: {
        pImg: v1img,
        ps1img: v1img,
        psub1img1: v1img,
        psub1img2: v2img,
        psub1img3: v3img,
        psub1img4: v4img,
        psub1img5: v5img,
        psub1img6: v6img,
        psub1img7: v7img,
        psub1img8: v8img,
        psub1img9: v9img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ბაკურიანი კოტეჯის ტიპის საცხოვრებელი სახლები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: v1img,
        ps1img: v1img,
        psub1img1: v1img,
        psub1img2: v2img,
        psub1img3: v3img,
        psub1img4: v4img,
        psub1img5: v5img,
        psub1img6: v6img,
        psub1img7: v7img,
        psub1img8: v8img,
        psub1img9: v9img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ბაკურიანი კოტეჯის ტიპის საცხოვრებელი სახლები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a7",

    data: {
      ka: {
        pImg: q6img,
        ps1img: q6img,
        psub1img1: q1img,
        psub1img2: q2img,
        psub1img3: q3img,
        psub1img4: q4img,
        psub1img5: q5img,
        psub1img6: q6img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "თიანეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: q6img,
        ps1img: q6img,
        psub1img1: q1img,
        psub1img2: q2img,
        psub1img3: q3img,
        psub1img4: q4img,
        psub1img5: q5img,
        psub1img6: q6img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "თიანეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a8",

    data: {
      ka: {
        pImg: r1img,
        ps1img: r1img,
        psub1img1: r1img,
        psub1img2: r2img,
        psub1img3: r3img,
        psub1img4: r4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "დუშეთი-ბაზალეთის ტბა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: r1img,
        ps1img: r1img,
        psub1img1: r1img,
        psub1img2: r2img,
        psub1img3: r3img,
        psub1img4: r4img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "დუშეთი-ბაზალეთის ტბა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a9",

    data: {
      ka: {
        pImg: t1img,
        ps1img: t1img,
        psub1img1: t1img,
        psub1img2: t2img,
        psub1img3: t3img,
        psub1img4: t4img,
        psub1img4: t5img,
        psub1img4: t6img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "კოტეჯის ტიპის საცხოვრებელი სახლი ლისზე",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: t1img,
        ps1img: t1img,
        psub1img1: t1img,
        psub1img2: t2img,
        psub1img3: t3img,
        psub1img4: t4img,
        psub1img4: t5img,
        psub1img4: t6img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "კოტეჯის ტიპის საცხოვრებელი სახლი ლისზე",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a10",

    data: {
      ka: {
        pImg: g1img,
        ps1img: g1img,
        psub1img1: g1img,
        psub1img2: g2img,
        psub1img3: g3img,
        psub1img4: g4img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: g1img,
        ps1img: g1img,
        psub1img1: g1img,
        psub1img2: g2img,
        psub1img3: g3img,
        psub1img4: g4img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "დიღომი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a11",

    data: {
      ka: {
        pImg: h1img,
        ps1img: h1img,
        psub1img1: h1img,
        psub1img2: h2img,
        psub1img3: h3img,
        psub1img4: h4img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "მისაქციელი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: h1img,
        ps1img: h1img,
        psub1img1: h1img,
        psub1img2: h2img,
        psub1img3: h3img,
        psub1img4: h4img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "მისაქციელი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a12",

    data: {
      ka: {
        pImg: k1img,
        ps1img: k1img,
        psub1img1: k1img,
        psub1img2: k2img,
        psub1img3: k3img,
        psub1img4: k4img,
        psub1img5: k5img,
        psub1img6: k6img,
        psub1img7: k7img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ჰოსპიტალი კომერციული ფართი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: k1img,
        ps1img: k1img,
        psub1img1: k1img,
        psub1img2: k2img,
        psub1img3: k3img,
        psub1img4: k4img,
        psub1img5: k5img,
        psub1img6: k6img,
        psub1img7: k7img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ჰოსპიტალი კომერციული ფართი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a13",

    data: {
      ka: {
        pImg: y1img,
        ps1img: y1img,
        psub1img1: y1img,
        psub1img2: y2img,
        psub1img3: y3img,
        psub1img4: y4img,
        psub1img5: y5img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ქოშიგორა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: y1img,
        ps1img: y1img,
        psub1img1: y1img,
        psub1img2: y2img,
        psub1img3: y3img,
        psub1img4: y4img,
        psub1img5: y5img,

        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ქოშიგორა ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a14",

    data: {
      ka: {
        pImg: ab1img,
        ps1img: ab1img,
        psub1img1: ab1img,
        psub1img2: ab2img,
        psub1img3: ab3img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ივერთუბანი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: ab1img,
        ps1img: ab1img,
        psub1img1: ab1img,
        psub1img2: ab2img,
        psub1img3: ab3img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ივერთუბანი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a15",

    data: {
      ka: {
        pImg: aa1img,
        ps1img: aa1img,
        psub1img1: aa1img,
        psub1img2: aa2img,
        psub1img3: aa3img,
        psub1img4: aa4img,
        psub1img5: aa5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "წოდორეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: aa1img,
        ps1img: aa1img,
        psub1img1: aa1img,
        psub1img2: aa2img,
        psub1img3: aa3img,
        psub1img4: aa4img,
        psub1img5: aa5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "წოდორეთი ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a16",

    data: {
      ka: {
        pImg: ae1img,
        ps1img: ae1img,
        psub1img1: ae1img,
        psub1img2: ae2img,
        psub1img3: ae3img,
        psub1img4: ae4img,
        psub1img5: ae5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "საგურამო ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: ae1img,
        ps1img: ae1img,
        psub1img1: ae1img,
        psub1img2: ae2img,
        psub1img3: ae3img,
        psub1img4: ae4img,
        psub1img5: ae5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "საგურამო ინდივიდუალურის საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a17",

    data: {
      ka: {
        pImg: aq1img,
        ps1img: aq1img,
        psub1img1: aq1img,
        psub1img2: aq2img,
        psub1img3: aq3img,
        psub1img4: aq4img,
        psub1img5: aq5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "თელავი სადეგუსტაციო კომერციული ფართი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: aq1img,
        ps1img: aq1img,
        psub1img1: aq1img,
        psub1img2: aq2img,
        psub1img3: aq3img,
        psub1img4: aq4img,
        psub1img5: aq5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "თელავი სადეგუსტაციო კომერციული ფართი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a18",

    data: {
      ka: {
        pImg: aw1img,
        ps1img: aw1img,
        psub1img1: aw1img,
        psub1img2: aw2img,
        psub1img3: aw3img,
        psub1img4: aw4img,
        psub1img5: aw5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: aw1img,
        ps1img: aw1img,
        psub1img1: aw1img,
        psub1img2: aw2img,
        psub1img3: aw3img,
        psub1img4: aw4img,
        psub1img5: aw5img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a19",

    data: {
      ka: {
        pImg: az1img,
        ps1img: az1img,
        psub1img1: az1img,
        psub1img2: az2img,
        psub1img3: az3img,
        psub1img4: az4img,
        psub1img5: az5img,
        psub1img6: az6img,
        psub1img7: az7img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: az1img,
        ps1img: az1img,
        psub1img1: az1img,
        psub1img2: az2img,
        psub1img3: az3img,
        psub1img4: az4img,
        psub1img5: az5img,
        psub1img6: az6img,
        psub1img7: az7img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ტაბახმელა ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a20",

    data: {
      ka: {
        pImg: ac1img,
        ps1img: ac1img,
        psub1img1: ac1img,
        psub1img2: ac2img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ლისი ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: ac1img,
        ps1img: ac1img,
        psub1img1: ac1img,
        psub1img2: ac2img,
        title: "არქიტექტურა",
        subTitle: "დიზაინი",
        description: "ლისი ინდივიდუალური საცხოვრებელი სახლი",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },

  {
    id: "a21",

    data: {
      ka: {
        pImg: ar1img,
        ps1img: ar1img,
        psub1img1: ar1img,
        psub1img2: ar2img,
        psub1img3: ar3img,
        psub1img4: ar4img,
        psub1img5: ar5img,
       
        title: "სარემონტო სამუშაოები",
        subTitle: "სარემონტო სამუშაოები",
        description: "კეკელიძის ქუჩა- სარემონტო სამუშაოები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: ar1img,
        ps1img: ar1img,
        psub1img1: ar1img,
        psub1img2: ar2img,
        psub1img3: ar3img,
        psub1img4: ar4img,
        psub1img5: ar5img,
        title: "Repair works",
        subTitle: "Repair works",
        description: "Kekelidze Street - repair works",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "Repair works",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a22",

    data: {
      ka: {
        pImg: ay1img,
        ps1img: ay1img,
        psub1img1: ay1img,
        psub1img2: ay2img,
        psub1img3: ay3img,
        psub1img4: ay4img,
        
       
        title: "სარემონტო სამუშაოები",
        subTitle: "სარემონტო სამუშაოები",
        description: "ბათუმის ქუჩა-სარემონტო სამუშაოები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
      en: {
        pImg: ay1img,
        ps1img: ay1img,
        psub1img1: ay1img,
        psub1img2: ay2img,
        psub1img3: ay3img,
        psub1img4: ay4img,
        title: "Repair works",
        subTitle: "Repair works",
        description: "Batumi street-repair works",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "არქიტექტურა",
        duration: "",
        completion: "",
      },
    },
  },
  {
    id: "a23",

    data: {
      ka: {
        pImg: av1img,
        ps1img: av1img,
        psub1img1: av1img,
        psub1img2: av2img,
        psub1img3: av3img,
        psub1img4: av4img,
        
       
        title: "სარემონტო სამუშაოები",
        subTitle: "სარემონტო სამუშაოები",
        description: "ავალიშვილის ქუჩა- სარემონტო სამუშაოები",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "Repair works",
        duration: "",
        completion: "",
      },
      en: {
        pImg: av1img,
        ps1img: av1img,
        psub1img1: av1img,
        psub1img2: av2img,
        psub1img3: av3img,
        psub1img4: av4img,
        title: "Repair works",
        subTitle: "Repair works",
        description: "Avalishvili street - renovation works",
        location: "თბილისი",
        client: "",
        architect: "",
        projectType: "Repair works",
        duration: "",
        completion: "",
      },
    },
  },

];
