import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../../components/ModalVideo";
import aboutimg from "../../images/aboutphoto.jpg"
import { useParams } from 'react-router-dom';
import { AboutI18n } from "../../i18n/about";




                                            // <<<<<<<<<<< ეს არის მთავარი გვერდის ჩვენ შესახებ >>>>>>>>>>>>





const Aboutsingle2 = (props) => {

  const {lang='ka'} =useParams()

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className={`wpo-about-area section-padding ${props.abClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={aboutimg} alt="er" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                  <span>{
                    AboutI18n[lang]['text5']
                  }</span>
                <h2>
                  {
                    AboutI18n[lang]['text1']
                  }
                </h2>
                
              </div>
              <h5>
                {
                  AboutI18n[lang]['text2']
                }
                
              </h5>
              
              {/* <p>
                {
                  AboutI18n[lang]['text3']
                }
              </p> */}
              <div className="btns">
                <Link to={`/${lang}/about`} onClick={ClickHandler} className="theme-btn">
                {
                  AboutI18n[lang]['text4']
                }
                </Link>
                {/* <ul>
                  <li className="video-holder">
                    <VideoModal />
                  </li>
                  <li className="video-text">Watch Our Video</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutsingle2;
