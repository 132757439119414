
// import simg from "../images/service-single/arcitecur1.jpg";
import simg from "../images/service-single/arcitecur1.jpg";
import simg2 from "../images/service-single/mshenebloba.png";
import simg3 from "../images/service-single/dizaini.jpg";
import simg4 from "../images/service-single/remonti.jpg";
import simg5 from "../images/service-single/5.jpg";
import simg6 from "../images/service-single/6.jpg";
import simg7 from "../images/service-single/7.jpg";
import simg8 from "../images/service-single/8.jpg";
import simg9 from "../images/service-single/9.jpg";
import simg10 from "../images/service-single/10.jpg";
import simg11 from "../images/service-single/11.jpg";
import simg12 from "../images/service-single/12.jpg";
import simg13 from "../images/service-single/13.jpg";
import renovation from "../images/renovation.png";

import sSingleimg1 from "../images/service-single/s1.jpg";
import sSingleimg2 from "../images/service-single/s2.jpg";

import ins1 from "../images/instragram/7.jpg";
import ins2 from "../images/instragram/8.jpg";
import ins3 from "../images/instragram/9.jpg";
import ins4 from "../images/instragram/10.jpg";
import ins5 from "../images/instragram/11.jpg";
import ins6 from "../images/instragram/12.jpg";




const Services =  [



 

  {
    
    Id: "1",
    sImg: simg,
    sTitle: "არქიტექტურა",
    description:
      "ურბანული დაგეგმარება, სტრუქტურული ინჟინერია, MEP ინჟინერია, სანებართვო {{{დეკოლაინის ექსპერტული დამოკიდებულება საქმისადმი და შესრულებული პროექტების რაოდენობა განაპირობებს სანდოობის }}}...",
    des2: "თანამედროვე არქიტექტურაში მნიშვნელოვანი კომპლექსური მიდგომა და ინდივიდუალიზმის შენარჩუნება ...      ",
    des3: "დეკოლაინის ექსპერტული დამოკიდებულება საქმისადმი და შესრულებული პროექტების რაოდენობა განაპირობებს სანდოობის მაღალ ხარისხსა და რეპუტაციას, რომლის გამოც, კომპანია სამშენებლო ბაზარზე, წლებია, ლიდერ პოზიციებზე იმყოფება.",
    icon: "fi flaticon-planning",
    
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins1,
  },
  {
    Id: "2",
    sImg: simg2,
    sTitle: "მშენებლობა",
    description:
      "ინდივიდუალური საცხოვრებელი ბინა, აგარაკი, მრავალსართულიანი...",
    des2: "კომპანია გთავაზობთ სამშენებლო სამუშაოებს ნებისმიერი სირთულის პროექტისათვის. მომსახურება მოიცავს მშენებლობის...",
    des3: (
      <div>
        <p>
          დეკოლაინის სამშენებლო სექტორი აერთიანებს მაღალკვალიფიციურ ინჟინრებს,
          პროექტ მენეჯერებს, ფინანსისტებს, აქვს უსაფრთხოების დაცვის უმაღლესი
          სტანდარტი, 24 საათიანი ვიდეომონიტორინგის საშუალება, ტექნიკური
          მხარდაჭერა, ხარისხის კონტროლი.
        </p>
        <ul>
          <li>კერძო ბინა</li>
          <li>აგარაკი</li>
          <li>ეკომერციული ობიექტი</li>
        </ul>
      </div>
    ),
    icon: "fi flaticon-blueprint",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins2,
  },
  {
    Id: "3",
    sImg: simg3,
    sTitle: "დიზაინი",
    description:
      "ინტერიერისა და ექსტერიერის, განათების, ლანდშაფტისა და არქიტექტურულ...",
    des2: "დიზაინის შექმნისას მნიშვნელოვანია პროფესიონალური ხედვა, ექსპერტული გამოცდილება და ინდივიდუალური ...    ",
    des3:  (
        <div>
          <p>
          ჩვენი მთავარი გამოწვევაა ისეთი დიზაინის შექმნა, რომელიც დამკვეთისთვის გახდება კომფორტისა და სრულყოფილების გარანტი.
          </p>
          <p>
            <h4>დიზაინერული მომსახურება მოიცავს:</h4>
          </p>
          <ul>
            <li>ინტერიერის დიზაინი</li>
            <li>ექსტერიერის დიზაინი</li>
            <li>ლანდშაფტის დიზაინი</li>
            <li>განათების დიზაინი</li>
          </ul>
        </div>
      ),
    icon: "fi flaticon-double-bed",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins3,
  },
  {
    Id: "4",
    sImg: simg4,
    sTitle: "რემონტი",
    description:
      "ნებისმიერი ტიპის კომერციულ და არაკომერციულ ობიექტზე სრული...",
    des2: "ნებისმიერი ტიპის კომერციულ და არაკომერციულ ობიექტზე სრული სარემონტო მომსახურება: შავი, თეთრი და მწვანე კარკასი...",
    des3: (
        <div>
          <p>
            <h3>სარემონტო მომსახურება მოიცავს შემდეგ მიმართულებებს. :</h3>
          </p>
          <ul>
            <li>დემონტაჟი</li>
            <li>შენება (აგურით/ბლოკით)</li>
            <li>საიზოლაციო სამუშაოები</li>
            <li>ელექტროგაყვანილობა და ვენტილაცია</li>
            <li>წყალმომარაგება</li>
            <li>გათბობა/გაგრილება</li>
            <li>ლესვა: გაჯით, ქვიშა&ცემენტით, როტბანდით</li>
            <li>მოჭიმული იატაკი: თვითსწორებადი, სტანდარტული</li>
            <li>სამალიარო სამუშაოები: დაშპაკვლა, შეღებვა, შპალერის გაკვრა</li>
            <li>კაფელ-მეტლახის სამუშაოები</li>
            <li>მეტლახის, მდფ-ის, პლასტმასისა და ხის პლინტუსის გაკვრა</li>
            <li>ლამინატისა და პარკეტის მონტაჟი; იატაკის მოხვეწა (ციკლოვკა)</li>
            <li>საბრიზგი სამუშაოები</li>
            <li>თაბაშირ-მუყაოს ფილების სამონტაჟო სამუშაოები: ჭერები, ტიხრები</li>
            <li> ფრანგული გასაჭიმი ჭერის მონტაჟი; (ბარისოლი); ამსტრონგის ჭერის მონტაჟი</li>
            <li>მეტალის, MDF-ისა და ხის კარებების მონტაჟი</li>
            <li>მეტალის ნაკეთობების ღებვა; ვიტრაჟების მოწყობა</li>
            <li>მეტალო-პლასტმასის სამონტაჟო სამუშაოები</li>
            <li>სამშენებლო ნაგვის გატანა; დალაგება, დასუფთავება.</li>
          </ul>
        </div>
      ),
    icon: "fi flaticon-armchair",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins4,
  },
  // {
  //     Id: '5',
  //     sImg:simg5,
  //     sTitle: 'Complete Decoration',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     icon:'fi flaticon-furniture',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins5,
  // },
  // {
  //     Id: '6',
  //     sImg:simg6,
  //     sTitle: 'Exterior Design',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     icon:'fi flaticon-interior-design',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '7',
  //     sImg:simg7,
  //     sTitle: 'Interior Design',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     icon:'fi flaticon-interior-design',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '8',
  //     sImg:simg8,
  //     sTitle: 'Room Decoration',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '9',
  //     sImg:simg9,
  //     sTitle: 'Planning',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '10',
  //     sImg:simg10,
  //     sTitle: 'Lighting',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '11',
  //     sImg:simg11,
  //     sTitle: 'Interior Design',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '12',
  //     sImg:simg12,
  //     sTitle: 'Room Decoration',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
  // {
  //     Id: '13',
  //     sImg:simg13,
  //     sTitle: 'Planning',
  //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //     ssImg1:sSingleimg1,
  //     ssImg2:sSingleimg2,
  //     sinst:ins6,
  // },
];
export default Services;
